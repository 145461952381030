/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import block from "bem-cn";
import PropTypes from "prop-types";
import SVGInline from "react-svg-inline";

import { validateEmail } from "services/helper/validators";
import passwordSVG from "./img/password.svg";
import passwordOffSVG from "./img/passwordOff.svg";

import "./Input.scss";

const b = block("input-mobile");

const Input = ({
  color = "default",
  fontSize = "16",
  value,
  name = "text",
  placeholder = "",
  type = "text",
  onChange,
  isRequired,
  ...restProps
}) => {
  useEffect(() => {
    if (value) {
      if (name === "email") restProps.setIsValid?.(validateEmail(value));
      else restProps.setIsValid?.(value.toString().length > 0);
    }
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const isPassword = useMemo(() => name.indexOf("password") > -1, [name]);
  const passwordClick = useCallback(
    () => setPasswordVisible(!passwordVisible && isPassword),
    [passwordVisible, isPassword]
  );

  return (
    <div className={b({ color, fontSize })}>
      {isPassword && (
        <>
          <SVGInline
            svg={passwordSVG}
            className={b("password", { active: !passwordVisible })}
            onClick={passwordClick}
          />
          <SVGInline
            svg={passwordOffSVG}
            className={b("password-off", { active: passwordVisible })}
            onClick={passwordClick}
          />
        </>
      )}
      <input
        className={b("native", {
          type: isPassword ? "password" : type,
          activePassword: isPassword && !passwordVisible,
          valid: restProps.isValid,
        })}
        type={/* isPassword */ passwordVisible ? "text" : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e);
          if (name === "email")
            restProps.setIsValid?.(validateEmail(e.target.value));
          else restProps.setIsValid?.(e.target.value.toString().length > 0);
        }}
        required={isRequired}
      />
    </div>
  );
};

Input.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  restProps: PropTypes.object,
  fontSize: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Input;
