import React, { useEffect, useMemo, useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import addNotify from 'features/notify';

import { actions } from 'features/users/redux';
import Button from 'components/Button';
import Input from 'components/Input';

import PopUp from './PopUp';

import './UserEdit.scss';

const b = block('user-edit');

const UserEdit = ({ match }) => {
  const dispatch = useDispatch();

  const [topUpMoney, changeTopUpMoney] = useState('');
  const [withDrawalMoney, changeWithdrawalMoney] = useState('');
  const [popUpIsOpen, setPopUpOpened] = useState(false);

  const id = match.params.id;
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const actionProcessing = useSelector(state => state.users.actionProcessing, shallowEqual);
  const usersList = useSelector(state => state.users.usersList.users, shallowEqual);
  const newPassword = useSelector(state => state.users.newPassword, shallowEqual);
  const history = useHistory();

  const user = useMemo(() => usersList?.find(t => +t.id === +id) || {}, [id, usersList]);

  useEffect(() => {
    if (!usersList) {
      dispatch(actions.getUsersList());
    }
  }, [dispatch, usersList]);

  /* const onChangeValue = useCallback(
    e => {
      const isTopUp = +e.target.getAttribute('data-key') ? true : false;
      if (isTopUp) {
        if (!withDrawalMoney) changeTopUpMoney(e.currentTarget.value);
      } else {
        if (!topUpMoney) changeWithdrawalMoney(e.currentTarget.value);
      }
    },
    [topUpMoney, withDrawalMoney],
  ); */
  const handleChangeTopUpValue = useCallback(
    e => {
      if (!withDrawalMoney) changeTopUpMoney(e.currentTarget.value);
    },
    [topUpMoney, withDrawalMoney],
  );
  const handleChangeWithdrawalValue = useCallback(
    e => {
      if (!topUpMoney) changeWithdrawalMoney(e.currentTarget.value);
    },
    [topUpMoney, withDrawalMoney],
  );

  const paymentButtonText = useMemo(() => {
    if (!topUpMoney && !withDrawalMoney) return locale.topUpWithdrawal;
    if (!topUpMoney && withDrawalMoney) return locale.withdrawal;
    if (topUpMoney && !withDrawalMoney) return locale.topUp;
  }, [locale.topUp, locale.topUpWithdrawal, locale.withdrawal, topUpMoney, withDrawalMoney]);

  const onPaymentClick = useCallback(() => {
    if (!actionProcessing) {
      dispatch(
        actions.changeUserBalance(
          id,
          topUpMoney || withDrawalMoney,
          topUpMoney ? false : true,
          topUpMoney ? changeTopUpMoney : changeWithdrawalMoney,
        ),
      );
    }
    // history.push('/users-list');
  }, [actionProcessing, dispatch, history, id, topUpMoney, withDrawalMoney]);

  const onBanClick = useCallback(
    (e, isBan) => {
      /* const value = +e.target.getAttribute('data-key') ? true : false; */
      if (!actionProcessing) dispatch(actions.changeBanState(id, isBan /* value */));
      history.push('/users-list');
    },
    [actionProcessing, dispatch, history, id],
  );

  const onResetClick = useCallback(() => {
    if (!actionProcessing) dispatch(actions.resetPassword(id, setPopUpOpened));
  }, [actionProcessing, dispatch, id]);

  return (
    <React.Fragment>
      {popUpIsOpen && <PopUp password={newPassword} id={id} locale={locale} onClose={setPopUpOpened} />}
      <div className={b()}>
        <div className={b('info')}>
          <div className={b('info-item')}>
            <div className={b('info-title')}>{locale.id}</div>
            <div className={b('info-value')}>{user.id}</div>
          </div>
          <div className={b('info-item')}>
            <div className={b('info-title')}>{locale.nickname}</div>
            <div className={b('info-value')}>{user.nickname}</div>
          </div>
          <div className={b('info-item')}>
            <div className={b('info-title')}>{locale.balance}</div>
            <div className={b('info-value')}>{user.balance}</div>
          </div>
        </div>
        <div className={b('payment')}>
          <div className={b('payment-item')}>
            <div className={b('payment-title')}>{locale.topUp}</div>
            <Input type="number" value={topUpMoney} onChange={handleChangeTopUpValue} data-key={1} />
          </div>
          <div className={b('payment-item')}>
            <div className={b('payment-title')}>{locale.withdrawal}</div>
            <Input type="number" value={withDrawalMoney} onChange={handleChangeWithdrawalValue} data-key={0} />
          </div>
          <div className={b('payment-button')}>
            <Button onClick={onPaymentClick}>
              <span className={b('button-text')}>{paymentButtonText}</span>
            </Button>
          </div>
        </div>
        <div className={b('buttons')}>
          <div className={b('reset-button')}>
            <Button onClick={onResetClick}>
              <span className={b('button-text')}>{locale.resetPassword}</span>
            </Button>
          </div>
          <div className={b('buttons-bottom')}>
            <div className={b('ban-button')} data-key={1}>
              <Button onClick={e => onBanClick(e, true)}>
                <span className={b('button-text')}>{locale.ban}</span>
              </Button>
            </div>
            <div className={b('unban-button')} data-key={0}>
              <Button onClick={e => onBanClick(e, false)} color="white">
                <span className={b('button-text2')}>{locale.unban}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserEdit;
