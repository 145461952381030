import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions } from 'features/users/redux';

import Input from 'components/Input';
import InputSearch from 'components/InputSearch';

import searchSvg from './img/search.svg';
import arrowSvg from './img/arrow.svg';

import './UsersList.scss';

const b = block('users-list');

const UsersList = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);
  const user = useSelector(state => state.auth);
  const allUsers = {
    ...usersList,
    users: [{ id: user.id, nickname: user.name, balance: user.balance }, ...usersList.users],
  };

  const list = useMemo(
    () =>
      allUsers.users
        ?.filter(t => ~t.nickname?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .sort((a, b) => b.balance - a.balance)
        .map(item => (
          <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
            <div className={b('item-nick', { banned: item.is_banned })}>{`${item.nickname} (ID: ${item.id})`}</div>
            <div className={b('item-right')}>
              <div className={b('item-balance')}>{item.balance}</div>
              <SVGInline svg={arrowSvg} className={b('item-arrow').toString()} />
            </div>
          </Link>
        )) || [],
    [filterValue, usersList],
  );

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <div className={b('filters-header')}>{locale.userCardBalances}</div>
        <div className={b('filters-search')}>
          <InputSearch value={filterValue} onChange={changeFilterValue} placeholder={`${locale.search}...`} />
          {/* <Input value={filterValue} onChange={changeFilterValue} /> */}
        </div>
      </div>

      <div className={b('subheader')}>
        <div className={b('subheader-item')}>
          <span className={b('subheader-text1')}>{locale.userCard}</span>
          <span className={b('subheader-text2')}>{usersList.quantity}</span>
        </div>
        <div className={b('subheader-item')}>
          <span className={b('subheader-text1')}>{locale.accountBalance}</span>
          <span className={b('subheader-text2')}>{usersList.totalBalance}</span>
        </div>
      </div>

      {/* <div className={b('subheader')}>
        <div className={b('subheader-text')}>
          <span>{locale.userCard}</span>
          <span>{locale.accountBalance}</span>
        </div>
        <div className={b('subheader-count')}>
          <span className={b('bold-text')}>{usersList.quantity}</span>
          <span className={b('bold-text')}>{usersList.totalBalance}</span>
        </div>
      </div> */}

      <div className={b('list')}>{list}</div>
    </div>
  );
};

export default UsersList;
