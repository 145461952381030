import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Changer langue',
  changePas: 'Changer pasword',
  logout: 'Deconnecter',
  other: 'Other',

  newPlayer: 'Nouveau client',
  newAgent: 'New agent',
  userName: 'Nom de utilisateur',
  password: 'Mot de passe',
  createAccount: 'Creer un compte',
  '/user-create/1': 'Caisse enregistreuse - nouveau joueur',
  '/user-create/2': 'Caisse enregistreuse - nouveau caissier',
  '/user-create/3': 'Caisse enregistreuse - nouvel administrateur',
  '/user-create/6': 'Caisse enregistreuse - nouvel super agent',
  '/total': 'cash register - comptabilitee',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/auth': "S'identifier",
  '/change-pas': 'Changer pasword',
  '/user-edit': 'Cash register - User edit',
  '/cashier-jackpot': 'Cash Registre - Cashier Jackpot',
  '/users-list': 'cash register - balance des clients',
  '/tree': 'Arbre',
  userCardBalances: 'Balances des clients',
  userCard: 'clients',
  accountBalance: 'balance de compte',
  nickname: 'Nom de utilisateur',
  id: 'Identifiant',
  balance: 'Balance',
  ban: 'Bloquer',
  banSuccess: 'Bloquer success',
  unbanSuccess: 'Debloquer success',
  unban: 'Debloquer',
  resetPassword: 'Réinitialiser',
  resetSuccess: 'Mot de passe réinitialisee',
  networkError: 'Erreur de reseau',
  createSuccess: 'Succès de creation',
  topUp: 'Depot',
  withdrawal: 'Retrait',
  topUpSuccess: 'Succès  de depot',
  withdrawalSuccess: 'Succès  de retrait',
  topUpWithdrawal: 'Depot/Retrait',
  ok: 'Ok',
  theNewPassword: 'nouveau mot de passe ',
  for: 'pour',

  '/transaction-history': 'clients - historique de transactions',
  startDate: 'Date de début',
  endingDate: 'Fin',
  display: 'representer',
  email: 'E-mail',
  date: 'Date',
  amount: 'montant',

  languageSelection: 'Sélection de la langue',
  login: 'Me Connecter',

  cashRegister: 'Cash register',
  partners: 'clients',
  createUser: {
    1: 'Nouveau joueur',
    2: 'Nouveau caissier',
    3: 'Nouvel administrateur',
    6: 'Nouvel super agent',
    /* 7: 'New agent', */
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
    6: 'Admins cards balances',
    7: 'Admins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
    6: 'Admins transactions',
    7: 'Admins transactions',
  },
  transactionHistory: 'Transaction history',
  revenuBalances: 'Revenu balances',

  total: 'Totale',
  term: 'terme',
  cashPayment: 'payement en espece',
  cashPayout: 'deboursement en espece',
  withdraw: 'Retrait',
  deposit: 'Depot',
  search: 'Search',
  errorPassword:
    'Le mot de passe doit comprendre: au moins 8 caractères, au moins un chiffre, une majuscule et une minuscule!',
  noPass: 'Le mot de passe ne doit pas être vide!',
  currency: 'Devise',
  totalDeposit: 'Dépôt total',
  totalWithdrawal: 'Retrait total',

  jackpotWinnersList: 'Liste des gagnants de Jackpot',
  startAmount: 'Montant de départ',

  winnerID: 'ID gagnant',
  nickname: 'Surnom',
  winDate: 'Remporter la date',
  amountOfTheWinningJackpot: 'Montant du jackpot gagnant',

  cashierJackpot: 'Cashier Jackpot',

  changedPasswordDoNotMatch: 'Les mots de passe ne correspondent pas',
  enterOldPassword: "Entrez l'ancien mot de passe",
  enterNewPassword: 'Entrez un nouveau mot de passe',
  repeatNewPassword: 'Répété le nouveau mot de passe',

  treeText: {
    1: 'Arbre',
    2: 'Arbre',
    3: 'Arbre',
    6: 'Arbre',
  },
  players: 'Joueurs',
  subusers: 'Nombre de sous-utilisateurs',
  tree: 'Arbre',
  balance: 'Balance',
};
