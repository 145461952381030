import React, { useState, useCallback, useMemo, forwardRef, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import { actions } from 'features/transaction';

import InputDate from 'components/InputDate';
import Button from 'components/Button';
import Input from 'components/Input';
import InputSearch from 'components/InputSearch';

import searchSvg from '../img/search.svg';
import calendarSvg from './img/calendar.svg';

import TransactionItem from './TransactionItem';

import './TransactionHistory.scss';

const b = block('transaction-history');

const TransactionHistory = () => {
  const dispatch = useDispatch();

  const [filterValue, onChangeFilterValue] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { transactions, totalDeposit, totalWithdrawal } = useSelector(state => state.transaction, shallowEqual);

  const [startDate, setStartDate] = useState(dayjs(new Date()).add(-3, 'day').$d);
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    dispatch(actions.getTransactions({ startDate, endDate }));
  }, [dispatch, endDate, startDate]);

  useEffect(() => {
    onClick();
  }, [onClick]);

  const list = useMemo(
    () =>
      transactions
        .filter(t => ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .map((item, index) => <TransactionItem key={`${item.email}_${index}`} item={item} />) || [],
    [filterValue, transactions],
  );

  /* const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
      <span className={b('custom-date-input-value')}>{value}</span>
      <SVGInline className={b('custom-date-input-icon').toString()} svg={calendarSvg} />
    </div>
  ));
  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
      <span className={b('custom-date-input-value')}>{value}</span>
      <SVGInline className={b('custom-date-input-icon').toString()} svg={calendarSvg} />
    </div>
  )); */

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <div className={b('filters-title')}>{locale.startDate}</div>
        <div className={b('filters-title')}>{locale.endingDate}</div>
        <div className={b('filters-input')}>
          <InputDate
            dateFormat="yyyy-MM-dd"
            selected={new Date(startDate)}
            onChange={date => {
              setStartDate(dayjs(date).format('YYYY-MM-DD'));
            }}
            isValid={Boolean(startDate)}
            title="date title"
          />
        </div>
        <div className={b('filters-input')}>
          <InputDate
            dateFormat="yyyy-MM-dd"
            selected={new Date(endDate)}
            onChange={date => {
              setEndDate(dayjs(date).format('YYYY-MM-DD'));
            }}
            isValid={Boolean(endDate)}
            title="date title"
          />
        </div>
      </div>
      <div className={b('filters-search')}>
        <InputSearch value={filterValue} onChange={changeFilterValue} placeholder={`${locale.search}...`} />
      </div>
      <div className={b('search-button')}>
        <Button onClick={onClick}>
          <span className={b('search-button-text')}>{locale.display}</span>
        </Button>
      </div>
      <div className={b('result-wrapper')}>
        <div className={b('users-header')}>
          <span className={b('user-header-item', { type: 'left' })}>{locale.email}</span>
          <span className={b('user-header-item', { type: 'center' })}>{locale.date}</span>
          <span className={b('user-header-item', { type: 'center' })}>{locale.amount}</span>
          <span className={b('user-header-item', { type: 'center' })}>{locale.balance}</span>
        </div>
        <div className={b('items')}>{list}</div>
        <div className={b('total')}>
          <p className={b('total-item')}>
            {locale.totalDeposit}
            <span className={b('total-value')}>{totalDeposit}</span>
          </p>
          <p className={b('total-item')}>
            {locale.totalWithdrawal}
            <span className={b('total-value')}>{totalWithdrawal}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
