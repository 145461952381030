import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import Input from 'components/Input';
import Button from 'components/Button';

import { actions } from '../redux';
import lock from './img/lock.svg';
import logoSvg from './img/logo.svg';

import './Auth.scss';

const b = block('auth');

const Auth = () => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');
  
  const locale = useSelector(state => state.locale.locale);

  const signIn = useCallback(() => dispatch(actions.login(userName, password)), [userName, password, dispatch]);

  return (
    <section className={b()}>
      {/* <div className={b("title")}>{locale.login}</div> */}
      {/* <SVGInline svg={logoSvg} className={b("icon")} /> */}
      <div className={b('input-block')}>
        <div className={b('label')}>{`${locale.userName}:`}</div>
        <Input
          value={userName}
          onChange={e => changeUserName(e.currentTarget.value)}
          name="userName"
          placeholder={locale.userName}
        />
      </div>
      <div className={b('input-block')}>
        <div className={b('label')}>{`${locale.password}:`}</div>
        <Input
          value={password}
          onChange={e => passwordUserName(e.currentTarget.value)}
          type="password"
          name="password"
          placeholder={locale.password}
        />
      </div>
      <div className={b('bottom')}>
        <div className={b('button')}>
          <Button onClick={signIn}>
            <span className={b('button-text')}>{locale.login} </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Auth;
