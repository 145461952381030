import React from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';

import './TransactionItem.scss';

let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone');

const b = block('transaction-item');

const TransactionItem = ({ item }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tunis = new Date(item.date.replaceAll('.', '/')).setMilliseconds(1 * 60 * 60 * 1000);
  const formatTunis = dayjs(tunis).format('YYYY.MM.DD HH:mm');

  return (
    <div className={b()}>
      <span className={b('column', { type: 'left' })}>{item.email}</span>
      <span className={b('column', { type: 'center' })}>{formatTunis}</span>
      <span className={b('column', { type: 'center' })}>{item.amount}</span>
      <span className={b('column', { type: 'center' })}>{item.balance}</span>
    </div>
  );
};

export default TransactionItem;
