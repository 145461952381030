import React from "react";
import block from "bem-cn";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import SVGInline from "react-svg-inline";

import { actions as authActions } from "features/Auth";

// import settings from './img/settings.svg';
import logoutSVG from "./img/logout.svg";
import passSVG from "./img/change-pass.svg";
import langSVG from "./img/change-lang.svg";

import "./Footer.scss";

const b = block("footer");

const Footer = ({ locale, isAuth }) => {
  const dispatch = useDispatch();

  return (
    <footer className={b()}>
      <div className={b("title")}>{locale.other}</div>
      <Link to="/locale" className={b("item")}>
        <div className={b("icon-container")}>
          <SVGInline
            svg={langSVG}
            className={b("icon").toString()}
            style={{ width: "1.5625rem", height: "1.5625rem" }}
          />
        </div>
        {locale.changeLang}
      </Link>
      {isAuth && (
        <Link to="/change-pas" className={b("item")}>
          <div className={b("icon-container")}>
            <SVGInline
              svg={passSVG}
              className={b("icon").toString()}
              style={{ width: "2.125rem", height: "2.125rem" }}
            />
          </div>
          {locale.changePas}
        </Link>
      )}
      {isAuth && (
        <div
          className={b("item")}
          onClick={() => dispatch(authActions.logOut())}
        >
          <div className={b("icon-container")}>
            <SVGInline
              svg={logoutSVG}
              className={b("icon").toString()}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </div>
          {locale.logout}
        </div>
      )}
    </footer>
  );
};

export default Footer;
