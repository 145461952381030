import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import { addNotify } from 'features/notify';
import { actions } from 'features/users/redux';
import Input from 'components/Input';
/* import PopUp from 'features/users/view/UserEdit/PopUp'; */

import './ChangePas.scss';
import Button from 'components/Button';

const b = block('change-pas');

const ChangePas = () => {
  const dispatch = useDispatch();

  const [oldPassword, editOldPassword] = useState('');
  const [newPassword, editNewPassword] = useState('');
  const [repeatNewPassword, editRepeatNewPassword] = useState('');

  const [popUpIsOpen, setPopUpOpened] = useState(false);
  // const [passwordDirty, setPasswordDirty] = useState(true);
  // const [passwordError, setPasswordError] = useState(locale.errorPassword);
  // const [isValid, setIsValid] = useState(true);

  const { id } = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  /* useEffect(() => {
    if (passwordError) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [passwordError]); */

  /* const blureHandler = e => {
    switch (e.target.name) {
      case 'password':
        setPasswordDirty(true);
        break;
    }
  }; */

  /* const passwordHandler = e => {
    setPas(e.target.value);
    const re = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9]{6,}[a-zA-Z]{1,}/;
    if (!re.test(String(e.target.value))) {
      setPasswordError(locale.errorPassword);
      if (!e.target.value) {
        setPasswordError(locale.noPass);
      }
    } else {
      setPasswordError(null);
    }
  }; */

  const onSubmit = useCallback(
    e => {
      console.log('dfgdfg1');
      e.preventDefault();
      console.log('dfgdfg2');
      if (newPassword === repeatNewPassword) {
        console.log('dfgdfg3');
        dispatch(actions.resetPassword(id, setPopUpOpened, newPassword, oldPassword));
      } else dispatch(addNotify(locale.changedPasswordDoNotMatch, 'error'));
    },
    [dispatch, id, locale, newPassword, oldPassword, repeatNewPassword],
  );

  return (
    <div className={b()}>
      {/* {popUpIsOpen && <PopUp password={pas} id={id} locale={locale} onClose={setPopUpOpened} />}
    {(passwordError && passwordDirty) && <div className={b('title')}>{passwordError}</div>} */}
      <div className={b('title')}>{locale.changePas}</div>
      <form className={b('form')} onSubmit={onSubmit}>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.enterOldPassword}:`}</div>
          <div className={b('field-input')}>
            <Input
              type="password"
              value={oldPassword}
              onChange={e => editOldPassword(e.currentTarget.value)}
              placeholder={locale.enterOldPassword}
              name="old-password"
              /* onBlur={e => blureHandler(e)} */
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.enterNewPassword}:`}</div>
          <div className={b('field-input')}>
            <Input
              type="password"
              value={newPassword}
              onChange={e => editNewPassword(e.currentTarget.value)}
              placeholder={locale.enterNewPassword}
              name="new-password"
              /* onBlur={e => blureHandler(e)} */
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.repeatNewPassword}:`}</div>
          <div className={b('field-input')}>
            <Input
              type="password"
              value={repeatNewPassword}
              onChange={e => editRepeatNewPassword(e.currentTarget.value)}
              placeholder={locale.repeatNewPassword}
              name="repeat-new-password"
              /* onBlur={e => blureHandler(e)} */
            />
          </div>
        </div>
        <div className={b('button')} /* disabled={!isValid} */ /* onClick={() => onResetClick()} */>
          <Button type="submit">
            <span className={b('button-text')}>{locale.changePas}</span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePas;
