import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector, connect, shallowEqual } from 'react-redux';
import { Switch, Route, withRouter, Link, useHistory, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import searchSvg from './img/search.svg';
import crossSVG from './img/cross.svg';

import './InputSearch.scss';

const b = block('input-search-mobile');

const InputSearch = ({ value, name, placeholder = '', onChange, ...restProps }) => {
  return (
    <div className={b()}>
      <SVGInline svg={searchSvg} className={b('search-icon')} />
      <input
        className={b('input')}
        name={name}
        type="search"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...restProps}
      />
      <SVGInline className={b('cross').toString()} svg={crossSVG} style={{ opacity: value ? 1 : 0 }} />
    </div>
  );
};

InputSearch.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  restProps: PropTypes.object,

  onChange: PropTypes.func.isRequired,
};

export default InputSearch;
