import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import arrowSvg from './img/arrow.svg';
import homeSvg from './img/home.svg';
import menuSvg from './img/menu.svg';

import './Header.scss';

const b = block('header');

const Header = ({ location, history }) => {
  const locale = useSelector(state => state.locale.locale);
  const { isAuth, role } = useSelector(state => state.auth);
  const isNeedBackButton = useMemo(
    () => location.pathname !== '/' && location.pathname !== '/auth',
    [location.pathname],
  );

  const onClick = useCallback(() => {
    if (isNeedBackButton) history.goBack();
  }, [history, isNeedBackButton]);

  const temp = location.pathname.lastIndexOf('/') === 0 ? location.pathname.length : location.pathname.lastIndexOf('/');
  let text = locale[location.pathname.substring(0, temp)];
  if (location.pathname === '/user-create') text = locale[`/user-create/${role}`];
  if (location.pathname === '/auth' && !isAuth) text = locale['/auth'];

  return (
    <div className={b()}>
      <div className={b('left')} onClick={onClick}>
        {location.pathname === '/auth' ? (
          <SVGInline svg={homeSvg} className={b('icon')} />
        ) : location.pathname === '/' ? (
          <SVGInline svg={menuSvg} className={b('icon')} />
        ) : null}
        {isNeedBackButton && <SVGInline className={b('arrow').toString()} svg={arrowSvg} />}
      </div>
      <div className={b('center')}>
        <div className={b('text')}>{text}</div>
      </div>
      <div className={b('right')} />
    </div>
  );
};

export default withRouter(Header);
