import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import spinnerGif from './img/logo.gif';
import './Spinner.scss';

export const Spinner = ({ isLoading, size = 'default', isRoulette = false }) => {
  const b = block('spinner');
  return isLoading ? (
    <div className={b('wrapper', { loading: isLoading })}>
      <img src={spinnerGif} className={b('logo')} alt="" />
    </div>
  ) : null;
};

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRoulette: PropTypes.bool,
  size: PropTypes.string,
};
